import "./App.css";
import Header from "./Components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Contact from "./Pages/Contacts";
import Book from "./Pages/Book";
import ScrollPage from "./Components/scrollpageUp";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contacts" element={<Contact />} />
          <Route path="/book" element={<Book />} />
        </Routes>
        <ScrollPage />
      </BrowserRouter>
    </div>
  );
}

export default App;
